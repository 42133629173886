import ForgotPasswordScreen from "../Pages/account/ForgotPasswordScreen/ForgotPasswordScreen";
import RecoverAcountScreen from "../Pages/account/RecoverAcountScreen/RecoverAcountScreen";
import VerifyAccountScreen from "../Pages/account/VerifyAccountScreen/VerifyAccountScreen";
import NotificationScreen from "../Pages/notification/NotificationScreen";
import OnboardingExpensesScreen from "../Pages/onboarding/Onboarding/OnboardingExpensesScreen";
import OnboardingProfitScreen from "../Pages/onboarding/Onboarding/OnboardingProfitScreen";
import SigninScreen from "../Pages/onboarding/SigninScreen/SigninScreen";
import SignupScreen from "../Pages/onboarding/SignupScreen/SignupScreen";
import WelcomeScreen from "../Pages/onboarding/WelcomeScreen/WelcomeScreen";
import PrivacyPolicyScreen from "../Pages/privacyPolicy/PrivacyPolicyScreen";
import TermsAndConditionsScreen from "../Pages/termsAndConditions/TermsAndConditionsScreen";
// import SuccessPageScreen from '../Pages/success/SuccessPageScreen'
import BusinessScreen from "../Pages/dashboard/BusinessScreen";
import StoriesScreen from '../Pages/dashboard/StoriesScreen'
import AddScreen from "../Pages/dashboard/AddScreen";
import PortfolioScreen from "../Pages/dashboard/PortfolioScreen";
import RewardScreen from "../Pages/dashboard/RewardScreen";
import ReferScreen from "../Pages/dashboard/ReferScreen";
import FinancialServicesScreen from '../Pages/dashboard/FinancialServicesScreen';
import SaveEmergencyScreen from "../Pages/dashboard/SaveEmergencyScreen";
import SalesScreen from "../Pages/dashboard/SalesScreen";
import ExpenseScreen from "../Pages/dashboard/ExpenseScreen";
import DebtScreen from "../Pages/dashboard/DebtScreen";
import DebtorsScreen from "../Pages/dashboard/DebtorsScreen";


export const routes = [ 
        {
            path: '/onboarding2',
            component:  OnboardingExpensesScreen,
            protected: false
        },
        {
            path: '/onboarding3',
            component:  OnboardingProfitScreen,
            protected: false
        },
        {
            path: '/welcome',
            component:  WelcomeScreen,
            protected: false
        },
        {
            path: '/signup',
            component:  SignupScreen,
            protected: false
        },
        {
            path: '/signin',
            component:  SigninScreen,
            protected: false
        },
        {
            path: '/forgot-password',
            component:  ForgotPasswordScreen,
            protected: false
        },
        {
            path: '/verify-account',
            component:  VerifyAccountScreen,
            protected: false
        },
        {
            path: '/recover-account',
            component:  RecoverAcountScreen,
            protected: false
        },
        {
            path: '/notifications',
            component: NotificationScreen,
            protected: false
        },
        {
            path: '/privacy-policy',
            component: PrivacyPolicyScreen,
            protected: false
        },
        {
            path: '/terms-conditions',
            component: TermsAndConditionsScreen,
            protected: false
        },
        {
            path: '/dashboard/business',
            component: BusinessScreen,
            protected: true
        },
        {
            path: '/dashboard/stories',
            component: StoriesScreen,
            protected: true
        },
        {
            path: '/dashboard',
            component: AddScreen,
            protected: true
        },
        {
            path: '/dashboard/rewards',
            component: RewardScreen,
            protected: true
        },
        {
            path: '/dashboard/portfolio',
            component: PortfolioScreen,
            protected: true
        },
        {
            path: '/dashboard/refer',
            component: ReferScreen,
            protected: true
        },
        {
            path: '/dashboard/financial-services',
            component: FinancialServicesScreen,
            protected: true
        },
        {
            path: '/dashboard/saving-emergency',
            component: SaveEmergencyScreen,
            protected: true
        },
        {
            path: '/dashboard/sales',
            component: SalesScreen,
            protected: true
        },
        {
            path: '/dashboard/expense',
            component: ExpenseScreen,
            protected: true
        },
        {
            path: '/dashboard/debtors',
            component: DebtorsScreen,
            protected: true
        },
        {
            path: '/dashboard/debts',
            component: DebtScreen,
            protected: true
        },
]
