import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import LayoutComponent from '../../Components/dashboard/LayoutComponent'
import Chart from "react-google-charts"

function PortfolioScreen() {
    return (
        <LayoutComponent>
            <Box
                 sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'left',
                    mb: '20px'
                }}
            >
                <Box
                    sx={{
                        textAlign: 'center',
                        width: '100%'
                    }}
                >
                    <Typography
                        sx={{
                            textAlign: 'center',
                            color: '#001533',
                            fontSize: '17px',
                            lineHeight: '22px',
                            fontWeight: 700
                        }}
                    >My Portfolio</Typography>
                    <Chart
                        width={'100%'}
                        height={'400px'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={[
                            ['Portfolio', 'items'],
                            ['Savings', 5],
                            ['Profit', 3],
                            ['Emergency funds', 2],
                        ]}
                        options={{
                            title: '',
                            // Just add this option
                            is3D: false,
                            pieHole: 0.4
                        }}
                        // rootProps={{ 'data-testid': '2' }}
                    />
                </Box>
                <Box
                    sx={{
                        mb: '40px'
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '23px',
                            fontWeight: 700,
                            lineHeight: '22px',
                            mb: '20px'
                        }}
                    >Savings</Typography>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mt: '15px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '15px',
                                lineHeight: '22px',
                                fontWeight: 700
                            }}
                        >Savings balance</Typography>
                        <Typography
                            sx={{
                                fontSize: '15px',
                                lineHeight: '22px',
                                fontWeight: 700
                            }}
                        >GHS 10,000.21</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        mb: '40px'
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '23px',
                            fontWeight: 700,
                            lineHeight: '22px',
                            mb: '20px'
                        }}
                    >Profits</Typography>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mt: '15px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '15px',
                                lineHeight: '22px',
                                fontWeight: 700
                            }}
                        >Monthly</Typography>
                        <Typography
                            sx={{
                                fontSize: '15px',
                                lineHeight: '22px',
                                fontWeight: 700
                            }}
                        >GHS 16,000</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mt: '15px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '15px',
                                lineHeight: '22px',
                                fontWeight: 700
                            }}
                        >Yearly</Typography>
                        <Typography
                            sx={{
                                fontSize: '15px',
                                lineHeight: '22px',
                                fontWeight: 700
                            }}
                        >GHS 8,000</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        mb: '40px'
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '23px',
                            fontWeight: 700,
                            lineHeight: '22px',
                            mb: '20px'
                        }}
                    >Emergency funds</Typography>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mt: '15px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '15px',
                                lineHeight: '22px',
                                fontWeight: 700
                            }}
                        >Total balance</Typography>
                        <Typography
                            sx={{
                                fontSize: '15px',
                                lineHeight: '22px',
                                fontWeight: 700
                            }}
                        >GHS 5,000</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        mb: '40px'
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '23px',
                            fontWeight: 700,
                            lineHeight: '22px',
                            mb: '20px'
                        }}
                    >Finance</Typography>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mt: '15px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '15px',
                                lineHeight: '22px',
                                fontWeight: 700
                            }}
                        >Total balance</Typography>
                        <Typography
                            sx={{
                                fontSize: '15px',
                                lineHeight: '22px',
                                fontWeight: 700
                            }}
                        >GHS 5,000</Typography>
                    </Box>
                </Box>
                
            </Box>
        </LayoutComponent>
    )
}

export default PortfolioScreen
