import { Avatar, Box, TextField, Typography } from '@mui/material'
import React from 'react'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import { IoIosSearch } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import Offers from '../../Assets/offers.png'
import LayoutComponent from '../../Components/dashboard/LayoutComponent'

function FinancialServicesScreen() {
    const navigate = useNavigate()
    return (
        <LayoutComponent>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'left',
                    gap: '50px',
                    width: '100%',
                    height: '100%',
                    background: '#ffffff',
                    // p:'46.42px 20px 20px',
                    overflowX: 'hidden'
                }}
            >
                <MdOutlineArrowBackIosNew onClick={() => navigate('/dashboard/rewards')} />
                <Typography
                    sx={{
                        fontSize: '30px',
                        lineHeight: '33.18px',
                        color: 'rgba(0, 21, 51, 0.5)',
                    }}
                >Financial Services</Typography>
                <Typography
                    sx={{
                        fontSize: '17px',
                        lineHeight: '22.2px',
                        color: '#001533',

                    }}
                >Financial service offers</Typography>
                <TextField
                    sx={{
                        background: '#F5F6FB',
                        border: '1px solid #F5F6FB'
                    }}  
                    fullWidth 
                    label={
                        <Box 
                            sx={{
                                display: 'flex',
                                gap: '12.19px',
                                alignItems: 'center'
                            }}
                        > 
                            <IoIosSearch fontSize='20px' />
                            <Box
                                as='span'
                                sx={{
                                    fontSize: '15px',
                                    lineHeight: '22px',
                                    color: '#999999'
                                }}
                            >Search for investment</Box> 
                        </Box>} 
                    id="fullWidth" 
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            border: '0.5px solid #c9c9c9',
                            boxShadow: '0px 8px 12px rgba(153, 153, 153, 0.08)',
                            borderRadius: '8px',
                            width: '80%',
                            display: 'flex',
                            flexDirection: 'column',
                            p: '20px',
                        }}
                    >
                        <Avatar sx={{mb: '35px'}} src={Offers} alt='brand' />
                        <Typography
                            sx={{
                                fontSize: '15px',
                                lineHeight: '22px',
                                mb: '8px'
                            }}
                        >Timestersave</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: '15px',
                                        lineHeight: '18px',
                                    }}
                                >GHS 5,000</Typography>
                                <Typography
                                    sx={{
                                        fontSize: '13px',
                                        lineHeight: '18px',
                                    }}
                                >per unit</Typography>
                            </Box>
                            <Box
                                as='button'
                                sx={{
                                    fontSize: '13px',
                                    lineHeight: '22px',
                                    color: '#16be81',
                                    background: '#F1FDF9',
                                    borderRadius: '25px',
                                    border: '1px solid #F1FDF9'
                                }}
                            >Still selling</Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </LayoutComponent>
        
    )
}

export default FinancialServicesScreen
