import { Box, Typography } from '@mui/material'
import React from 'react'
import { MdOutlineArrowBackIosNew, MdOutlineContentCopy } from 'react-icons/md'
import Refer from '../../Assets/refer.png'
import { useNavigate } from 'react-router-dom'

function ReferScreen() {
    const navigate = useNavigate()
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'left',
                gap: '30px',
                width: '100%',
                height: '100%',
                background: '#ffffff',
                p:'46.42px 20px 20px',
                overflowX: 'hidden'
            }}
        >
            <MdOutlineArrowBackIosNew onClick={() => navigate('/dashboard/rewards')} />
            <Box 
                sx={{
                    height: '400px',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <img src={Refer} alt="income" />
            </Box>
            <Box
                sx={{
                    mb: '60px',
                    textAlign: 'center'
                }}
            >
                <Typography 
                    sx={{
                        fontWeight: 500,
                        fontSize: '30px',
                        lineHeight: '22px',
                        color: '#001533',
                        mb: '7px'
                    }}
                >
                    Get Free 100 points
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '15px',
                        lineHeight: '22px',
                        color: '#001533',
                    }}
                >
                    You and your friends earn cash reward when they signup and save with your referral link or code.
                </Typography>
            </Box>
            <Typography
                sx={{
                    fontSize: '17px',
                    lineheight: '22px',
                    fontweight: 400,
                    color: '#00204D'
                }}
            >Here is your referral code</Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    as='button'
                    sx={{
                        background: 'rgba(0, 102, 246, 0.2)',
                        width: '60%',
                        height: '50px',
                        borderRadius: '5px',
                        border: '1px solid rgba(0, 102, 246, 0.2)',
                        p: '0 20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'

                    }}
                >
                    BEATRICE123
                    <MdOutlineContentCopy />
                </Box>
                <Box
                    as='button'
                    sx={{
                        background: '#0066F6',
                        color: '#ffffff',
                        height: '50px',
                        borderRadius: '5px',
                        border: '1px solid rgba(0, 102, 246, 0.2)',
                        p: '0 20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'

                    }}
                >
                    INVITE
                </Box>
            </Box>
        </Box>
    )
}

export default ReferScreen
