import { createAsyncThunk } from '@reduxjs/toolkit';


export const signupUser = createAsyncThunk(
    'users/signupUser',
    async ({ full_name, phonenumber, email, password }, thunkAPI) => {
      try {
        const response = await fetch(
          'https://jidipwa-api.herokuapp.com/user/authentication/account/',
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              full_name,
              phonenumber,
              email,
              password,
            }),
          }
        );
        let data = await response.json();
        // console.log('data', data);
  
        if (response.status === 201) {
            // sessionStorage.setItem('access', data.access);
            // localStorage.setItem('refresh', data.refresh);

            return {...data, full_name, email, phonenumber};
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e) {
        console.log('Error', e.response.data);
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
  
  export const loginUser = createAsyncThunk(
    'users/login',
    async ({ phonenumber, password }, thunkAPI) => {
      try {
        const response = await fetch(
          'https://jidipwa-api.herokuapp.com/user/authentication/login/',
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              phonenumber,
              password,
            }),
          }
        );
        let data = await response.json();
        // console.log('response', data);
        if (response.status === 200) {
            sessionStorage.setItem('access', data.access);
            localStorage.setItem('refresh', data.refresh);
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e) {
        console.log('Error', e.response.data);
        thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
  
  export const fetchUserBytoken = createAsyncThunk(
    'users/fetchUserByToken',
    async ({ token }, thunkAPI) => {
      try {
        const response = await fetch(
          'https://mock-user-auth-server.herokuapp.com/api/v1/users',
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              Authorization: token,
              'Content-Type': 'application/json',
            },
          }
        );
        let data = await response.json();
        // console.log('data', data, response.status);
  
        if (response.status === 200) {
          return { ...data };
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e) {
        console.log('Error', e.response.data);
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );