import React from 'react'
import LayoutComponent from '../../Components/dashboard/LayoutComponent'
import { Avatar, Box, Typography } from '@mui/material'
import { AiOutlineArrowUp } from 'react-icons/ai'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

function RewardScreen() {
    const navigate = useNavigate()
    return (
        <LayoutComponent>
            <Box
                 sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: '20px'
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: '25px',
                            lineHeight: '22px',
                            fontWeight: 700,
                            color: '#5E5E5E'
                        }}
                    >Hello Beatrice</Typography>
                    <Typography
                        sx={{
                            fontSize: '13px',
                            color: '#001533'
                        }}
                    >Keep up the good work winning points</Typography>
                </Box>
                
                <Avatar>H</Avatar>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    mb: '40px'
                }}
            >
                <Box
                    sx={{
                        background: '#0066F6',
                        color: '#ffffff',
                        height: '180px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '8px',
                        p: '0 50px',
                        boxShadow: '-10px 10px 20px rgba(153, 153, 153, 0.12), 10px 10px 24px rgba(153, 153, 153, 0.08)'
                    }}
                >
                    <Typography>
                        Jidi score
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: '37px',
                            lineHeight: '32px',
                            textAlign: 'center'
                        }}
                    >
                        55,100
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box
                        as='button'
                        sx={{
                            fontWeight: 400,
                            fontSize: '17px',
                            lineHeight: '22px',
                            background: '#D5F6EE',
                            color: '#5E5E5E',
                            p: '15px',
                            borderRadius: '8px',
                            width: '50%',
                            textAlign: 'center',
                            border: '0'
                        }}
                    >Goal-based susu</Box>
                    <Box
                        as='button'
                        sx={{
                            fontWeight: 400,
                            fontSize: '17px',
                            lineHeight: '22px',
                            background: '#D5F6EE',
                            color: '#5E5E5E',
                            p: '15px 25px',
                            borderRadius: '8px',
                            width: '45%',
                            textAlign: 'center',
                            border: '0'
                        }}
                    >Finance</Box>
                </Box>
                <Box as='button'
                    sx={{
                        fontWeight: 400,
                        fontSize: '17px',
                        lineHeight: '22px',
                        background: '#c9c9c9',
                        color: '#5E5E5E',
                        p: '15px 25px',
                        borderRadius: '8px',
                        width: '45%',
                        margin: '0 auto',
                        textAlign: 'center',
                        border: '0'
                    }}
                >
                    <Typography>
                        GHS 30.00
                    </Typography>
                    Finance
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <AiOutlineArrowUp fontSize={30} />
                    <Typography
                        sx={{
                            fontSize: '15px',
                            color: '#001533'
                        }}
                    >When you qualifiy this button will turn green</Typography>
                </Box>
                <Box
                    as='button'
                    sx={{
                        background: '#5299FF',
                        border: '1px solid #c9c9c9',
                        boxShadow: '0px 6px 8px rgba(153, 153, 153, 0.08)',
                        borderRadius: '8px',
                        height: '65px',
                        color: '#ffffff',
                        fontSize: '30px',
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',

                    }}
                    onClick={() => navigate('/dashboard/financial-services')}
                >
                    Financial Services
                    <Box
                        sx={{
                            background: '#ffffff',
                            p: '2px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <MdKeyboardArrowRight color='#001533'/>
                    </Box>
                </Box>
                <Typography
                    sx={{
                        fontSize: '17px',
                        fontWeight: 700,
                        color: '#001533'
                    }}
                >
                    Ways to earn more points
                </Typography>
                <Box
                    as='button'
                    sx={{
                        border: '1px solid #c9c9c9',
                        borderRadius: '8px',
                        boxShadow: '0px 6px 8px rgba(153, 153, 153, 0.08)',
                        height: '60px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: '0 25px',
                        background: '#ffffff'
                    }}
                    onClick={() => navigate('/dashboard/refer')}
                >
                    <Typography
                        sx={{
                            fontSize: '15px',
                            lineheight: '18px',
                            color: '#001533'
                        }}
                    >Invite your friends and get a bonus</Typography>
                    <MdKeyboardArrowRight color='#001533' fontSize={30}/>
                </Box>
                <Box
                    as='button'
                    sx={{
                        border: '1px solid #c9c9c9',
                        borderRadius: '8px',
                        boxShadow: '0px 6px 8px rgba(153, 153, 153, 0.08)',
                        height: '60px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: '0 25px',
                        background: '#ffffff'
                    }}
                    onClick={() => navigate('/dashboard/stories')}
                >
                    <Typography
                        sx={{
                            fontSize: '15px',
                            lineheight: '18px',
                            color: '#001533'
                        }}
                    >Watch some lessons</Typography>
                    <MdKeyboardArrowRight color='#001533' fontSize={30}/>
                </Box>
            </Box>
        </LayoutComponent>
    )
}

export default RewardScreen
