import React, { useState, useEffect } from 'react'
import { Box, Typography, Button, CircularProgress } from '@mui/material'
import { MdClear } from 'react-icons/md'
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { userSelector, clearState } from '../../../Store/user/userSlice'
import { signupUser } from '../../../Store/user/userActions'
import toast, { Toaster } from 'react-hot-toast'

function SignupScreen() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const  [ state, setState ] = useState({
        full_name: '',
        email: '',
        phonenumber: '',
        password: ''
    })

    const { isFetching, isSuccess, isError, errorMessage } = useSelector(userSelector)

    const onSubmit = () => {
        dispatch(signupUser(state))
    }

    useEffect(() => {
        return () => {
          dispatch(clearState());
        };
    });
    
    useEffect(() => {
        if (isSuccess) {
            dispatch(clearState());
            toast.success('Account created successfully')
            navigate('/signin');
        }
    
        if (isError) {
          toast.error(errorMessage);
          dispatch(clearState());
        }
      }, [ isSuccess, isError, dispatch, navigate, errorMessage ]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'left',
                gap: '50px',
                width: '100%',
                height: '100%',
                background: '#ffffff',
                p: '20px 20px 20px',
                overflowX: 'hidden'
            }}
        >
            <MdClear fontSize='28px' onClick={() => navigate('/welcome')} />
            <Box>
                <Typography 
                    sx={{
                        fontWeight: 700,
                        fontSize: '35px',
                        lineHeight: '52.71px',
                        color: '#0066F6',
                        mb: '7px'
                    }}
                >
                    Create Account
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '15px',
                        lineHeight: '22.59px',
                        color: '#001533',
                    }}
                >
                    Open a Jidzi account with a few details.
                </Typography>
            </Box>
            <Box 
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px'
                }}
            >
                <label 
                    style={{ 
                        color: '#001533', 
                        fontSize: '15px',
                        lineHeight: '20px'
                    }} 
                    htmlFor='name'
                >
                    Full name
                </label>
                <input 
                    style={{ 
                        border: '1px solid #e4e4e4', 
                        borderRadius: '5px',
                        height: '50px',
                        padding: '16px',
                        marginBottom: '10px'
                    }} 
                    id='name'
                    name='full_name'
                    type="text" 
                    placeholder='Joseph Kalu' 
                    onChange={(e) => {
                        e.preventDefault();
                        setState(state => ({
                            ...state,
                            full_name: e.target.value
                        }))
                    }}
                />

                <label 
                    style={{ 
                        color: '#001533', 
                        fontSize: '15px',
                        lineHeight: '20px'
                    }} 
                    htmlFor='email'
                >
                    Email
                </label>
                <input 
                    style={{ 
                        border: '1px solid #e4e4e4', 
                        borderRadius: '5px',
                        height: '50px',
                        padding: '16px',
                        marginBottom: '10px'
                    }} 
                    id='email'
                    name='email'
                    type="text" 
                    placeholder='example@gmail.com' 
                    onChange={(e) => {
                        e.preventDefault();
                        setState(state => ({
                            ...state,
                            email: e.target.value
                        }))
                    }}
                />

                <label 
                    style={{ 
                        color: '#001533', 
                        fontSize: '15px',
                        lineHeight: '20px'
                    }} 
                    htmlFor='phone'
                >
                    Phone number
                </label>
                <input 
                    style={{ 
                        border: '1px solid #e4e4e4', 
                        borderRadius: '5px',
                        height: '50px',
                        padding: '16px',
                        marginBottom: '10px'
                    }}
                    id='phone' 
                    type="text" 
                    name='phonenumber'
                    placeholder='+ 234 808 762 1236' 
                    onChange={(e) => {
                        e.preventDefault();
                        setState(state => ({
                            ...state,
                            phonenumber: e.target.value
                        }))
                    }}
                />
                <label
                    style={{ 
                        color: '#001533', 
                        fontSize: '15px',
                        lineHeight: '20px'
                    }}  
                    htmlFor='password'
                >
                    Password
                </label>
                <input 
                    style={{ 
                        border: '1px solid #e4e4e4', 
                        borderRadius: '5px',
                        height: '50px',
                        padding: '16px',
                        marginBottom: '10px'
                    }}
                    id='password'
                    type="password"
                    name='password' 
                    placeholder='********' 
                    onChange={(e) => {
                        e.preventDefault();
                        setState(state => ({
                            ...state,
                            password: e.target.value
                        }))
                    }}
                />
                <label
                    style={{ 
                        color: '#001533', 
                        fontSize: '15px',
                        lineHeight: '20px'
                    }}  
                    htmlFor='repeat'
                >
                    Repeat password
                </label>
                <input
                    style={{ 
                        border: '1px solid #e4e4e4', 
                        borderRadius: '5px',
                        height: '50px',
                        padding: '16px',
                        marginBottom: '10px'
                    }} 
                    id='repeat' 
                    type="password" 
                    placeholder='********' 
                />
            </Box>
            <Box>
                {
                    isFetching? 
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                    :
                    <Button
                        sx={{
                            color: '#ffffff',
                            background: '#0066F6',
                            width: '100%',
                            height: '50px',
                            borderRadius: '5px',
                            mb: '20px'
                        }}
                        onClick={onSubmit}
                    >
                        Create your account
                    </Button>
                }

                <Typography
                    sx={{
                        color: '#001533',
                        fontSize: '15px',
                        lineHeight: '22.59px',
                    }}
                >
                    Do you already have a Jidzi account? <span onClick={() => navigate('/signin')} style={{ color: '#0066F6' }} >Sign in here</span>
                </Typography>
            </Box>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
        </Box>
    )
}

export default SignupScreen
