import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material'
import React from 'react'
import { IoHome } from 'react-icons/io5'
import { BiDoughnutChart } from 'react-icons/bi'
import { MdAddCircle } from 'react-icons/md'
import { HiCurrencyDollar } from 'react-icons/hi'
import { BsPersonCircle } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

function LayoutComponent({ children }) {
    const navigate = useNavigate()
    return (
        <Box>
            <Box
                sx={{
                    width: '100%',
                    p:'46.42px 20px 20px',
                    overflowX: 'hidden'
                }}
            >
                {children}
            </Box>
            <BottomNavigation
                showLabels
                sx={{
                    position: 'fixed',
                    bottom: '0',
                    left: '0',
                    width: '100%',
                    p: '0 10px',
                    mb: '0px'
                }}
            >
                <BottomNavigationAction onClick={() => navigate('/dashboard/business')} label='Business' icon={<IoHome fontSize={50} />} />
                <BottomNavigationAction onClick={() => navigate('/dashboard/stories')} label='Stories' icon={<BiDoughnutChart fontSize={50} />} />
                <BottomNavigationAction onClick={() => navigate('/dashboard')} label='' icon={<MdAddCircle fontSize={60} color='#0066F6' />} />
                <BottomNavigationAction onClick={() => navigate('/dashboard/rewards')} label='Rewards' icon={<HiCurrencyDollar fontSize={50} />} />
                <BottomNavigationAction onClick={() => navigate('/dashboard/portfolio')} label='Portfolio' icon={<BsPersonCircle fontSize={50} />} />
            </BottomNavigation>
        </Box>
    )
}

export default LayoutComponent
