import { createSlice } from '@reduxjs/toolkit'
import {signupUser, loginUser, fetchUserBytoken} from './userActions'


export const userSlice = createSlice({
    name: 'user',
    initialState: {
        fullName: '',
        phone: '',
        email: '',
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
      clearState: (state) => {
        state.isError = false;
        state.isSuccess = false;
        state.isFetching = false;
  
        return state;
      },
    },
    extraReducers: {
      [signupUser.fulfilled]: (state, { payload }) => {
        // console.log('payload', payload);
        state.isFetching = false;
        state.isSuccess = true;
        state.email = payload.email;
        state.fullName = payload.full_name;
        state.phone = payload.phonenumber
      },
      [signupUser.pending]: (state) => {
        state.isFetching = true;
      },
      [signupUser.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.message;
      },
      [loginUser.fulfilled]: (state, { payload }) => {
        state.email = payload.user;
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      },
      [loginUser.rejected]: (state, { payload }) => {
        // console.log('payload', payload);
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.status;
      },
      [loginUser.pending]: (state) => {
        state.isFetching = true;
      },
      [fetchUserBytoken.pending]: (state) => {
        state.isFetching = true;
      },
      [fetchUserBytoken.fulfilled]: (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
  
        state.email = payload.email;
        state.username = payload.name;
      },
      [fetchUserBytoken.rejected]: (state) => {
        // console.log('fetchUserBytoken');
        state.isFetching = false;
        state.isError = true;
      },
    },
  });
  export const { clearState } = userSlice.actions;

export const userSelector = (state) => state.user;