import {Navigate} from 'react-router-dom'
// import { useSelector } from 'react-redux'
// import { userSelector } from '../Store/user/userSlice';

function PrivateRoute({ children }) {
    const refresh = sessionStorage.getItem('access')
   // const { email } = useSelector(userSelector)
    let useAuth = () => {
       return refresh ? true : false
    }
    const auth = useAuth();
    return auth ? children : <Navigate to="/signin" />;
  }

  export default PrivateRoute