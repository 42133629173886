import { Box, Button, TextField } from '@mui/material'
import React from 'react'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

function SaveEmergencyScreen() {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'left',
                gap: '70px',
                width: '100%',
                height: '100%',
                background: '#ffffff',
                p: '20px 20px 20px',
                overflowX: 'hidden'
            }}
        >
            <MdOutlineArrowBackIosNew onClick={() => navigate('/dashboard/rewards')} />
            <Box 
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    mb: '50px'
                }}
            >
                <TextField 
                    sx={{
                        mb: '10px'
                    }} 
                    id="outlined-basic" 
                    label="Amount" 
                    variant="outlined" 
                />
                <label 
                    style={{ 
                        color: '#001533', 
                        fontSize: '15px',
                        lineHeight: '20px'
                    }} 
                    htmlFor='name'
                >
                    Wallet name
                </label>
                <input 
                    style={{ 
                        border: '1px solid #e4e4e4', 
                        borderRadius: '5px',
                        height: '50px',
                        padding: '16px',
                        marginBottom: '10px'
                    }}
                    id='wa' 
                    type="text" 
                    name='walletAmt'
                    placeholder='school fees' 
                    
                />
                <label 
                    style={{ 
                        color: '#001533', 
                        fontSize: '15px',
                        lineHeight: '20px'
                    }} 
                    htmlFor='name'
                >
                    Target amount
                </label>
                <input 
                    style={{ 
                        border: '1px solid #e4e4e4', 
                        borderRadius: '5px',
                        height: '50px',
                        padding: '16px',
                        marginBottom: '10px'
                    }}
                    id='ta' 
                    type="text" 
                    name='targetAmt'
                    placeholder='Enter the amount  you want to reach' 
                    
                />
                <label 
                    style={{ 
                        color: '#001533', 
                        fontSize: '15px',
                        lineHeight: '20px'
                    }} 
                    htmlFor='name'
                >
                    Phone number
                </label>
                <input 
                    style={{ 
                        border: '1px solid #e4e4e4', 
                        borderRadius: '5px',
                        height: '50px',
                        padding: '16px',
                        marginBottom: '10px'
                    }}
                    id='phone' 
                    type="text" 
                    name='phonenumber'
                    placeholder='+234 806 123 8870' 
                    
                />
                <label 
                    style={{ 
                        color: '#001533', 
                        fontSize: '15px',
                        lineHeight: '20px'
                    }} 
                    htmlFor='name'
                >
                    Customer name
                </label>
                <input 
                    style={{ 
                        border: '1px solid #e4e4e4', 
                        borderRadius: '5px',
                        height: '50px',
                        padding: '16px',
                        marginBottom: '10px'
                    }}
                    id='cn' 
                    type="text" 
                    name='customerName'
                    placeholder='Joseph Kalu' 
                    
                />
                <label 
                    style={{ 
                        color: '#001533', 
                        fontSize: '15px',
                        lineHeight: '20px'
                    }} 
                    htmlFor='name'
                >
                    Pin
                </label>
                <input 
                    style={{ 
                        border: '1px solid #e4e4e4', 
                        borderRadius: '5px',
                        height: '50px',
                        padding: '16px',
                        marginBottom: '10px'
                    }}
                    id='pin' 
                    type="text" 
                    name='pin'
                    placeholder='****' 
                    
                />
            </Box>
            <Button
                sx={{
                    color: '#ffffff',
                    background: '#0066F6',
                    width: '90%',
                    height: '50px',
                    borderRadius: '5px',
                    mb: '20px',
                    position: 'absolute',
                    bottom: '0'
                }}
                
            >
                Save
            </Button>
        </Box>
    )
}

export default SaveEmergencyScreen
