import { Box, Button, TextField } from '@mui/material'
import React from 'react'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

function DebtScreen() {
    const navigate = useNavigate()
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                gap: '70px',
                width: '100%',
                height: '100%',
                background: '#ffffff',
                p: '20px 20px 20px',
                overflowX: 'hidden'
            }}
        >
            <MdOutlineArrowBackIosNew onClick={() => navigate('/dashboard')} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    mb: '50px'
                }}
            >
                <label 
                    style={{ 
                        color: '#001533', 
                        fontSize: '15px',
                        lineHeight: '20px'
                    }} 
                    htmlFor='name'
                >
                    Creditor's name
                </label>
                <input 
                    style={{ 
                        border: '1px solid #e4e4e4', 
                        borderRadius: '5px',
                        height: '50px',
                        padding: '16px',
                        marginBottom: '10px'
                    }}
                    id='name' 
                    type="text" 
                    name='name'
                    placeholder='John Doe' 
                    
                />
                <label 
                    style={{ 
                        color: '#001533', 
                        fontSize: '15px',
                        lineHeight: '20px'
                    }} 
                    htmlFor='name'
                >
                    Description
                </label>
                <input 
                    style={{ 
                        border: '1px solid #e4e4e4', 
                        borderRadius: '5px',
                        height: '50px',
                        padding: '16px',
                        marginBottom: '10px'
                    }}
                    id='desc' 
                    type="text" 
                    name='desc'
                    placeholder='Oraimo bluetooth speaker' 
                    
                />
                <TextField 
                    sx={{
                        mb: '10px'
                    }} 
                    id="outlined-basic" 
                    label="Amount" 
                    variant="outlined" 
                />
            </Box>
            <Button
                sx={{
                    color: '#ffffff',
                    background: '#0066F6',
                    width: '90%',
                    height: '50px',
                    borderRadius: '5px',
                    mb: '20px',
                    position: 'absolute',
                    bottom: '0'
                }}
                
            >
                Add
            </Button>
        </Box>
    )
}

export default DebtScreen
